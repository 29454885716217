<nb-card id="time-range-selector-card">
  <nb-card-header [ngClass]="{ 'header-row-padding': onlyTimeZoneSelection }" class="row" id="header-row">
    <label *ngIf="!onlyTimeZoneSelection" class="col-6">{{ 'dialogs.daterange.title' | translate }}</label>
    <app-timezone-selector [ngClass]="{ 'col-12': onlyTimeZoneSelection }" class="col-6"></app-timezone-selector>

    <div *ngIf="onlyTimeZoneSelection" id="message">
      <nb-icon icon="alert-circle-outline" status="success"></nb-icon>
      <small>{{ 'dialogs.daterange.onlyRecentValues' | translate }}</small>
    </div>
  </nb-card-header>

  <nb-card-body *ngIf="!onlyTimeZoneSelection">
    <div class="row" id="rangePicker">
      <div class="col-md-12 col-lg-7">
        <nb-calendar-range (rangeChange)="selectedRangeChange($event)" #rangepicker [showWeekNumber]="true">
        </nb-calendar-range>
      </div>

      <div id="time-range-form" [formGroup]="timeRangeForm" class="col-md-12 col-lg-5">
        <div class="row">
          <nb-button-group>
            <button *ngFor="let range of timeRanges" size="small" nbButtonToggle
              (click)="setPredefinedTimerange(range.range)" value="range.range" [pressed]="range.isSelected"
              class="col">{{ 'dialogs.daterange.' + range.range | translate }}</button>
          </nb-button-group>
        </div>

        <div class="row" id="single-selection">
          <div class="col-6">
            <h6 class="w-100">{{ 'common.From' | translate }}</h6>
            <nb-form-field>
              <nb-icon nbPrefix icon="calendar-outline" pack="eva" status="basic"></nb-icon>
              <input nbInput [nbDatepicker]="datepickerFrom" formControlName="fromDateForm" #datepickerFromInput
                class="w-100 date" [status]="getStatus('fromDateForm')" id="fromDateInput" />
              <nb-datepicker #datepickerFrom></nb-datepicker>
            </nb-form-field>
            <app-error-message-container [label]="'common.From' | translate"
              [form]="timeRangeForm.controls.fromDateForm"
              [messagePattern]="'dialogs.daterange.error.invalid' | translate: { syntax: format }">
            </app-error-message-container>
            <nb-form-field>
              <nb-icon nbPrefix icon="clock-outline" pack="eva" status="basic"></nb-icon>
              <input nbInput [nbTimepicker]="timepickerFrom" formControlName="fromTimeForm" #timepickerFromInput
                (blur)="focusLost(true)" (keydown)="keyDown($event, true)" class="w-100 time"
                [status]="getStatus('fromTimeForm')" id="fromTimeInput" />
              <nb-timepicker #timepickerFrom withSeconds></nb-timepicker>
            </nb-form-field>
            <p class="tz">{{ startTimeShiftLAbel }}</p>
          </div>
          <div class="col-6">
            <h6 class="w-100">{{ 'common.To' | translate }}</h6>
            <nb-form-field>
              <nb-icon nbPrefix icon="calendar-outline" pack="eva" status="basic"></nb-icon>
              <input nbInput [nbDatepicker]="datepickerTo" formControlName="toDateForm" #datepickerToInput
                class="w-100 date" [status]="getStatus('toDateForm')" id="toDateInput" />
              <nb-datepicker #datepickerTo></nb-datepicker>
            </nb-form-field>
            <app-error-message-container [label]="'common.To' | translate" [form]="timeRangeForm.controls.toDateForm"
              [messagePattern]="'dialogs.daterange.error.invalid' | translate: { syntax: format }">
            </app-error-message-container>
            <nb-form-field>
              <nb-icon nbPrefix icon="clock-outline" pack="eva" status="basic"></nb-icon>
              <input nbInput [nbTimepicker]="timepickerTo" formControlName="toTimeForm" #timepickerToInput
                (blur)="focusLost(false)" (keydown)="keyDown($event, false)" class="w-100 time"
                [status]="getStatus('toTimeForm')" id="toTimeInput" />
              <nb-timepicker #timepickerTo withSeconds></nb-timepicker>
            </nb-form-field>
            <p class="tz">{{ endTimeShiftLAbel }}</p>
          </div>
        </div>

        <div class="row" id="buttons">
          <div class="w-100 message-row">
            <ng-container *ngIf="timeRangeForm.errors?.invalidRange && timeRangeForm.touched && timeRangeForm.dirty">
              <p class="caption status-danger">
                {{ 'dialogs.daterange.error.invalidPeriod' | translate }}
              </p>
            </ng-container>
          </div>
          <button nbButton (click)="closeDialog.emit(true)">
            {{ 'control.button.cancel' | translate }}
          </button>
          <button nbButton (click)="applyChanges()" status="primary">
            {{ 'control.text.apply' | translate }}
          </button>
        </div>
      </div>
    </div>
  </nb-card-body>
</nb-card>
