import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NbAccordionModule, NbCheckboxModule, NbDialogRef } from '@nebular/theme';
import { TranslatePipe } from '@ngx-translate/core';
import { MatomoTracker } from 'ngx-matomo-client';
import { LocaleService } from 'src/app/@shared/services/locale.service';
import { CookieSettings } from '../../model/cookie-settings.model';
@Component({
  selector: 'app-cookie-details',
  templateUrl: './cookie-details.component.html',
  styleUrls: ['./cookie-details.component.scss'],
  imports: [
    TranslatePipe,
    NbCheckboxModule,
    FormsModule,
    NbAccordionModule,
  ]
})
export class CookieDetailsComponent implements OnInit {
  acceptAllValue = false;
  cookieSettings: CookieSettings;
  locale = 'en';

  constructor(
    protected nbDialogRef: NbDialogRef<any>,
    private localeService: LocaleService,
    private matomoTracker: MatomoTracker
  ) {
  }

  ngOnInit(): void {
    const existingSettings = localStorage.getItem('cookieSettings');
    if (existingSettings) {
      this.cookieSettings = JSON.parse(existingSettings);
    } else {
      this.cookieSettings = {
        requiredAccepted: true,
        performanceAccepted: false,
      };
      this.localeService.language$.subscribe((lang) => {
        if (lang) {
          this.locale = lang;
        }
      });
    }
  }

  // acceptAll has changed
  checkAll(): void {
    this.acceptAllValue = !this.acceptAllValue;
    this.updateSettings();
  }

  checkFunctional(): void {
    this.cookieSettings.functionalAccepted = !this.cookieSettings.functionalAccepted;
    if (!this.cookieSettings.functionalAccepted) {
      this.acceptAllValue = false;
    } else if (this.cookieSettings.functionalAccepted && this.cookieSettings.marketingAccepted && this.cookieSettings.performanceAccepted) {
      this.acceptAllValue = true;
    }
  }

  checkMarketing(): void {
    this.cookieSettings.marketingAccepted = !this.cookieSettings.marketingAccepted;
    if (!this.cookieSettings.marketingAccepted) {
      this.acceptAllValue = false;
    } else if (this.cookieSettings.functionalAccepted && this.cookieSettings.marketingAccepted && this.cookieSettings.performanceAccepted) {
      this.acceptAllValue = true;
    }
  }

  checkPerformance(): boolean {
    this.cookieSettings.performanceAccepted = !this.cookieSettings.performanceAccepted;
    if (!this.cookieSettings.performanceAccepted) {
      this.acceptAllValue = false;
      this.matomoTracker.trackEvent('cookie', 'performance', 'false');
      this.matomoTracker.forgetConsentGiven();
    } else if (this.cookieSettings.functionalAccepted && this.cookieSettings.marketingAccepted && this.cookieSettings.performanceAccepted) {
      this.acceptAllValue = true;
    }
    if (this.cookieSettings.performanceAccepted) {
      this.matomoTracker.rememberConsentGiven();
      this.matomoTracker.trackEvent('cookie', 'performance', 'true');
      return true;
    }
    return false;
  }

  saveSelected(): void {
    this.updateSettings();
    this.nbDialogRef.close(this.cookieSettings);
  }

  acceptAll(): void {
    this.acceptAllValue = true;
    this.updateSettings();
    this.matomoTracker.rememberConsentGiven();
    this.matomoTracker.trackEvent('tracking', 'accept-all-cookies', 'accept-all-cookies');
    this.nbDialogRef.close(this.cookieSettings);
  }

  private updateSettings(): void {
    if (this.acceptAllValue) {
      this.cookieSettings.performanceAccepted = true;
    } else {
      // tbd
    }
  }
}
