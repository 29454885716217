import { AsyncPipe, NgIf, TitleCasePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AuthService, User } from '@auth0/auth0-angular';
import { NbAccordionModule, NbCardModule, NbTabsetModule, NbThemeService } from '@nebular/theme';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { MatomoTracker } from 'ngx-matomo-client';
import { Observable } from 'rxjs';
import { InputComponent } from 'src/app/@shared/components/input/input.component';
import { CookieService } from 'src/app/@shared/services/cookie.service.';
import { LocaleService } from 'src/app/@shared/services/locale.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  imports: [
    NgIf,
    AsyncPipe,
    NbCardModule,
    NbTabsetModule,
    InputComponent,
    TranslatePipe,
    TitleCasePipe,
    NbAccordionModule
  ]
})
export class ProfileComponent implements OnInit {
  profileJson: string = null;
  userInfo;
  settings;
  darkmode: boolean;

  public user$: Observable<User>;

  constructor(
    public auth: AuthService,
    private cookieService: CookieService,
    public translate: TranslateService,
    private locale: LocaleService,
    private matomo: MatomoTracker,
    private themeService: NbThemeService
  ) {
    this.darkmode = this.themeService.currentTheme === 'innomotics-dark';
  }

  ngOnInit(): void {
    this.locale.language$.subscribe(() => {
      this.userInfo = this.translate.instant('header.profilePage.userInfo');
      this.settings = this.translate.instant('header.profilePage.settings');
    });

    this.user$ = this.auth.user$;
  }

  openCookieDetails(): void {
    this.matomo.trackEvent('profile', 'settings', 'openCookieDetails');
    this.cookieService.openCookieDetails();
  }

  switchStyleMode(darkmode: boolean): void {
    if (darkmode) {
      this.themeService.changeTheme('innomotics-dark');
    } else {
      this.themeService.changeTheme('innomotics-light');
    }
  }
}
