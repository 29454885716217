<div id="header">
  <div class="logo-container">
    <a id="logo" (click)="goToHomePage()" [routerLink]="['/home']">
      <img class="siemens-logo" src="assets/images/INNOMOTICS-logo-power-gray-RGB.svg" />
      <img class="iq-suite" src="assets/images/IQ Suite.svg" />
    </a>
    <app-version></app-version>
    <button nbButton ghost (click)="goToHomePage()" [routerLink]="['/home']">
      <nb-icon id="home" pack="eva" icon="home-outline" class="black"></nb-icon>
    </button>
    <button status="primary" nbButton ghost [nbPopover]="filterContainer" nbPopoverPlacement="bottom">
      <nb-icon pack="eva" icon="funnel-outline"></nb-icon>
    </button>
    <app-breadcrumb id="breadcrumbs"></app-breadcrumb>
  </div>
</div>
<div id="action-icons">
  <div id="search-container">
    <div class="search">
      <app-search-container *ngIf="!home"></app-search-container>
    </div>
    <div class="search-s" *ngIf="!home">
      <button status="primary" nbButton ghost [nbPopover]="collapsedSearch" nbPopoverPlacement="bottom">
        <nb-icon pack="eva" icon="search-outline"></nb-icon>
      </button>
    </div>
  </div>
  <div class="action-container">
    <ng-template #timeRangeSelector let-data let-ref="dialogRef" id="timeRangeSelector">
      <app-time-range-selector [onlyTimeZoneSelection]="onlyTimeZoneSelection" (closeDialog)="ref.close()">
      </app-time-range-selector>
    </ng-template>
    <app-time-range-selector-button [onlyTimeZoneSelection]="onlyTimeZoneSelection || home" cdkOverlayOrigin
      (click)="openTimeSelector(timeRangeSelector, true)">
    </app-time-range-selector-button>
    <button nbButton ghost class="burger-content">
      <nb-icon pack="eva" icon="person-outline" [nbContextMenu]="userMenu" nbContextMenuTag="user-context-menu"
        class="black">
      </nb-icon>
    </button>

    <button nbButton ghost class="burger-content">
      <nb-icon pack="eva" icon="question-mark-circle-outline" [nbContextMenu]="legalMenu"
        nbContextMenuTag="legal-context-menu" class="black"></nb-icon>
    </button>
  </div>
  <div class="menu-icon">
    <button nbButton ghost [nbContextMenu]="burgerMenu" nbContextMenuTag="burger-menu">
      <nb-icon pack="eva" icon="menu-outline" class="black"></nb-icon>
    </button>
  </div>
</div>
