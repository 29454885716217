import { CommonModule, NgFor } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NbButtonModule, NbCardModule, NbDialogRef, NbIconModule, NbRadioModule } from '@nebular/theme';
import { TranslatePipe } from '@ngx-translate/core';
import { MatomoModule, MatomoTracker } from 'ngx-matomo-client';
import { CoreModule } from 'src/app/@core/core.module';
import { LANG } from '../../services/language-keys';
import { LocaleService } from '../../services/locale.service';

@Component({
  selector: 'app-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.scss'],
  imports: [
    NbCardModule,
    NbRadioModule,
    MatomoModule,
    CommonModule,
    CoreModule,
    FormsModule,
    TranslatePipe,
    NbIconModule,
    NbButtonModule,
    NgFor,
  ],
})
export class LanguageComponent {
  public selectedOption: LANG | string = LANG.EN;
  options = [
    {
      value: LANG.EN,
      label: 'English',
      checked: true,
      url: 'assets/images/flag-en.png',
    },
    { value: LANG.DE, label: 'German', url: 'assets/images/flag-de.png' },
    { value: LANG.CN, label: 'Chinese', url: 'assets/images/flag-cn.png' },
    { value: LANG.ES, label: 'Spanish', url: 'assets/images/flag-es.png' },
    { value: LANG.PT, label: 'Portugese', url: 'assets/images/flag-pt.png' },
    { value: LANG.RU, label: 'Russian', url: 'assets/images/flag-ru.png' },
    { value: LANG.FR, label: 'French', url: 'assets/images/flag-fr.png' },
  ];

  constructor(
    protected dialogRef: NbDialogRef<any>,
    private localeService: LocaleService,
    private matomo: MatomoTracker,
  ) {
    const selected = localeService.getCurrentLanguage();
    if (selected) {
      this.selectedOption = selected;
    }
  }

  close(): void {
    this.matomo.trackEvent('language', this.selectedOption);

    this.localeService.switchLanguage(this.selectedOption).subscribe({
      next: () => {
        // new lang file loaded
        this.dialogRef.close();
      },
      error: (error) => {
        // tbd
        this.dialogRef.close();
      },
    });
  }
}