import { NgIf } from '@angular/common';
import { ChangeDetectorRef, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NavigationEnd, Router, RouterLink } from '@angular/router';
import {
  NbButtonModule,
  NbContextMenuModule,
  NbDialogService, NbIconModule, NbMediaBreakpointsService, NbMenuBag,
  NbMenuItem, NbMenuService, NbPopoverDirective, NbPopoverModule, NbSidebarService,
  NbThemeService
} from '@nebular/theme';
import { TranslateService } from '@ngx-translate/core';
import { Subject, Subscription } from 'rxjs';
import { filter, map, take, takeUntil } from 'rxjs/operators';
import { CollapsedSearchComponent } from 'src/app/@shared/components/collapsed-search/collapsed-search.component';
import { SearchContainerComponent } from 'src/app/@shared/components/search/search-container/search-container.component';
import { TimeRangeSelectorButtonComponent } from 'src/app/@shared/components/time-range-selector-button/time-range-selector-button.component';
import { TimeRangeSelectorComponent } from 'src/app/@shared/components/time-range-selector/time-range-selector.component';
import { LocaleService } from 'src/app/@shared/services/locale.service';
import { SessionStorageService } from 'src/app/@shared/services/session-storage.service';
import { FilterContainerComponent } from 'src/app/filter/filter-container/filter-container.component';
import { MobileAppComponent } from '../../../@shared/components/mobile-app/mobile-app.component';
import { Auth0Service } from '../../services/auth/auth.service';
import { LayoutService } from '../../services/layout.service';
import { BreadcrumbComponent } from '../breadcrumb/breadcrumb.component';
import { VersionComponent } from '../version/version.component';
@Component({
  standalone: true,
  selector: 'app-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
  imports: [
    RouterLink,
    VersionComponent,
    NbIconModule,
    NbPopoverModule,
    BreadcrumbComponent,
    SearchContainerComponent,
    NgIf,
    TimeRangeSelectorButtonComponent,
    NbContextMenuModule,
    TimeRangeSelectorComponent,
    NbButtonModule
  ]
})
export class HeaderComponent implements OnInit, OnDestroy {
  @ViewChild(NbPopoverDirective) popover: NbPopoverDirective;
  userPictureOnly = false;
  currentTheme = 'siemens';
  filterContainer = FilterContainerComponent;
  collapsedSearch = CollapsedSearchComponent;
  burgerMenu: Array<NbMenuItem> = [
    {
      title: '',
      data: {
        localizationKey: 'header.user',
        hrefLocalizationKey: 'header.user.link',
      },
      icon: 'person-outline',
    },
    {
      title: '',
      expanded: true,
      icon: 'question-mark-circle-outline',
      data: {
        localizationKey: 'header.info',
      },
      children: [
        {
          title: '',
          data: {
            href: '',
            localizationKey: 'header.corporateInfo',
            hrefLocalizationKey: 'header.corporateInfo.link',
          },
          icon: 'chevron-right-outline'
        },
        {
          title: this.translate.instant('header.termsOfUse'),
          data: {
            href: '',
            localizationKey: 'header.termsOfUse',
            hrefLocalizationKey: 'header.termsOfUse.link',
          },
          icon: 'chevron-right-outline'
        },
        {
          title: '',
          data: {
            href: '',
            localizationKey: 'header.openSource',
          },
          icon: 'chevron-right-outline'
        },
        {
          title: this.translate.instant('header.mobileApp'),
          data: {
            localizationKey: 'header.mobileApp',
            opens: 'mobileApp'
          },
          icon: 'chevron-right-outline'
        },
      ],
    },
    {
      title: this.translate.instant('header.logout'),
      data: {
        localizationKey: 'header.logout',
      },
      icon: 'power-outline',
    },
  ];
  userMenu = [
    {
      title: '',
      data: {
        opens: 'profile',
        localizationKey: 'header.profile',
      },
      icon: 'chevron-right-outline'
    },
    {
      title: '',
      data: {
        opens: 'logout',
        localizationKey: 'header.logout',
      },
      icon: 'chevron-right-outline'
    },
  ];
  legalMenu: Array<NbMenuItem> = [
    {
      title: this.translate.instant('header.corporateInfo'),
      data: {
        href: '',
        localizationKey: 'header.corporateInfo',
        hrefLocalizationKey: 'header.corporateInfo.link',
      },
      icon: 'chevron-right-outline'
    },
    {
      title: this.translate.instant('header.termsOfUse'),
      data: {
        href: '',
        localizationKey: 'header.termsOfUse',
        hrefLocalizationKey: 'header.termsOfUse.link',
      },
      icon: 'chevron-right-outline'
    },
    {
      title: this.translate.instant('header.openSource'),
      data: {
        href: '',
        localizationKey: 'header.openSource',
      },
      icon: 'chevron-right-outline'
    },
    {
      title: this.translate.instant('header.help'),
      data: {
        href: '',
        localizationKey: 'header.help',
        hrefLocalizationKey: 'header.help.link',
      },
      icon: 'chevron-right-outline'
    },
    {
      title: this.translate.instant('header.mobileApp'),
      data: {
        localizationKey: 'header.mobileApp',
        opens: 'mobileApp'
      },
      icon: 'chevron-right-outline'
    },
  ];
  tabs = [
    {
      title: '',
      route: '/home',
      icon: 'home-outline',
      responsive: true,
    },
  ];
  home: boolean; // do not show header search bar on home page
  onlyTimeZoneSelection = true; // disable time range picker for independent views

  timepickerUrls = [
    // [type, tab]
    [null, 'device/general'],
    [null, 'filedownload'],
    [null, 'motor-health'],
    [null, 'charts/charts'],
    [null, 'charts/spectra'],
    [null, 'charts/airgap'],
    [null, 'multichart/matrix'],
    [null, 'multichart/properties'],
    [null, 'operatingpoint/operatingpoint'],
    [null, 'operatingpoint/fingerprint'],
    [null, 'assetlogs/assetlogs'],
    [null, 'partialdischarge/overview'],
    [null, 'partialdischarge/3pard'],
    [null, 'partialdischarge/prpd'],
    [null, 'timeseries'],
    [null, 'nominal'],
    [null, 'overview/health_condition'],
    ['/1200/', 'overview/cooling'],
    ['/1300/', 'overview/bearings'],
    ['/1300/', 'overview/accumulator'],
    ['/1300/', 'overview/brake'],
    ['/1400/', 'overview/fingerprint'],
    ['/1500/', 'overview/fingerprint'],
    ['/1600/', 'overview/vibrationoverview'],
    ['/1600/', 'overview/airgap'],
    ['/1600/', 'overview/cooling'],
    ['/1700/', 'overview/fingerprint'],
    ['/2200/', 'overview/bearing_health'],
    ['/2200/', 'overview/pumpEventDetection'],
    ['/2200/', 'overview/blowerMonitoring'],
    ['/2500/', 'overview/bearing_health'],
    ['/2700/', 'overview/bearing_health'],
    ['/2800/', 'overview/bearing_health'],
    ['/2800/', 'overview/pumpEventDetection'],
    ['/3500/', 'overview/sensor_board'],
    ['/3500/', 'overview/smart_cell'],
    ['/assetmanagement/', 'notifications/audit']
  ];

  subscriptions: Array<Subscription> = [];
  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private themeService: NbThemeService,
    private sessionStorageService: SessionStorageService,
    private router: Router,
    private auth: Auth0Service,
    private breakpointService: NbMediaBreakpointsService,
    private sidebarService: NbSidebarService,
    private layoutService: LayoutService,
    private menuService: NbMenuService,
    private dialogService: NbDialogService,
    private localeService: LocaleService,
    public translate: TranslateService,
    private cdr: ChangeDetectorRef
  ) {
    let ossPath: string;
    if (window.env.version === 'latest') {
      ossPath = window.env.ossReadme + 'IQSuite_ReadMeOSS.html';
    } else {
      const versionShort = window.env.version?.split('-')[0];
      ossPath = window.env.ossReadme + versionShort + '/IQSuite_ReadMeOSS.html';
    }

    this.burgerMenu
      .find((menuItem) => menuItem.data.localizationKey === 'header.info').children
      .find((menuItem) => menuItem.data.localizationKey === 'header.openSource').data.href = ossPath;
    this.legalMenu
      .find((menuItem) => menuItem.data.localizationKey === 'header.openSource').data.href = ossPath;
  }

  ngOnInit(): void {
    this.home = this.router.url === '/';
    this.localizeAllMenus();
    // listen to url changes and hide search on home page
    this.subscriptions.push(
      this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
        this.home = this.router.url === '/home';
        this.onlyTimeZoneSelection = this.isOnlyTimeZoneSelection(event);
        this.popover.hide();
      })
    );

    this.currentTheme = this.themeService.currentTheme;

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService
      .onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$)
      )
      .subscribe((isLessThanXl: boolean) => (this.userPictureOnly = isLessThanXl));

    this.themeService
      .onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$)
      )
      .subscribe((themeName) => (this.currentTheme = themeName));

    this.menuService
      .onItemClick()
      .pipe(
        filter(({ tag, item }) => tag === 'legal-context-menu' && !(item as any).data.opens),
        map(({ item }) => (item as any).data.href)
      )
      .subscribe((href) => window.open(href, '_blank'));

    this.menuService
      .onItemClick()
      .pipe(
        filter(({ tag }) => tag === 'legal-context-menu' || tag === 'burger-menu'),
        filter(({ item }) => (item as any).data.opens === 'mobileApp')
      )
      .subscribe(() => {
        this.openMobileAppInfo();
      });

    this.menuService
      .onItemClick()
      .pipe(
        filter(({ tag }) => tag === 'user-context-menu'),
        filter(({ item }) => (item as any).data.opens === 'logout')
      )
      .subscribe(() => {
        this.sessionStorageService.clearAssets();
        this.auth.logout();
      });

    this.menuService
      .onItemClick()
      .pipe(
        filter(({ tag }) => tag === 'user-context-menu'),
        filter(({ item }) => (item as any).data.opens === 'profile')
      )
      .subscribe(() => this.goToProfile());

    this.menuService
      .onItemClick()
      .pipe(
        filter(({ tag }) => tag === 'burger-menu'),
        filter(({ item }) => (item as any).data.opens === 'profile')
      )
      .subscribe(() => this.goToProfile());

    this.menuService
      .onItemClick()
      .pipe(
        filter(({ tag }) => tag === 'burger-menu'),
        filter(
          ({ item }) =>
            (item as any).title === this.translate.instant('header.help') ||
            (item as any).title === this.translate.instant('header.corporateInfo') ||
            (item as any).title === this.translate.instant('header.termsOfUse') ||
            (item as any).title === this.translate.instant('header.openSource')
        ),
        map(({ item }) => (item as any).data.href)
      )
      .subscribe((href) => window.open(href, '_blank'));

    this.menuService
      .onItemClick()
      .pipe(
        filter(({ tag }) => tag === 'burger-menu'),
        filter(({ item }) => (item as any).data.opens === 'logout')
      )
      .subscribe(() => {
        this.sessionStorageService.clearAssets();
        this.auth.logout();
      });

    this.localeService.language$.subscribe((currentLanguage) => {
      if (currentLanguage) {
        this.localizeAllMenus();
      }
    });
    this.localizeAllMenus();
  }

  private localizeAllMenus(): void {
    this.localizeMenuItems(this.burgerMenu);
    this.localizeMenuItems(this.legalMenu);
    this.localizeMenuItems(this.userMenu);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }


  changeTheme(themeName: string): void {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();
    return false;
  }

  goToHomePage(): void {
    this.deselectAllMenuItems();
  }

  goToProfile(): void {
    this.deselectAllMenuItems();
    this.router.navigateByUrl('profile');
  }

  goToManual(): void {
    this.deselectAllMenuItems();
    this.router.navigateByUrl('manual');
  }

  homeActive(): boolean {
    if (this.router.url === '/home') {
      return true;
    } else {
      return false;
    }
  }

  private localizeMenuItems(menuItems: Array<any>): void {
    menuItems.forEach((menuItem) => {
      // localize the title of the menu item
      if (menuItem.data && menuItem.data.localizationKey) {
        menuItem.title = this.translate.instant(menuItem.data.localizationKey);
      }
      // get language specific links
      if (menuItem.data && menuItem.data.hrefLocalizationKey) {
        menuItem.data.href = this.translate.instant(menuItem.data.hrefLocalizationKey);
      }

      if (menuItem.children) {
        this.localizeMenuItems(menuItem.children);
      }
    });
  }

  private navigateHome(): void {
    this.menuService.navigateHome();
  }

  private deselectAllMenuItems(): void {
    // menu shall be the same tag as it is in the side-menu.component.html
    this.menuService
      .getSelectedItem('menu')
      .pipe(take(1))
      .subscribe((menuBag: NbMenuBag) => {
        if (menuBag && menuBag.item) {
          menuBag.item.selected = false;
        }
      });
  }

  openTimeSelector(dialog: TemplateRef<any>, hasBackdrop: boolean): void {
    this.cdr.markForCheck();
    this.dialogService.open(dialog, {
      hasBackdrop,
      closeOnEsc: true,
      autoFocus: false,
      dialogClass: 'timeSelectorDialog',
    });
    this.cdr.detectChanges();
  }

  isOnlyTimeZoneSelection(event: NavigationEnd): boolean {
    let isTZOnly = true;

    this.timepickerUrls.forEach((url) => {
      if (!url[0] && event.urlAfterRedirects.indexOf(url[1]) > -1) {
        isTZOnly = false;
      } else {
        if (event.urlAfterRedirects.indexOf(url[0]) > -1 && event.urlAfterRedirects.indexOf(url[1]) > -1) {
          isTZOnly = false;
        }
      }
    });

    return isTZOnly;
  }

  private openMobileAppInfo(): void {
    this.dialogService.open(MobileAppComponent, {
      closeOnBackdropClick: true,
      autoFocus: false,
    });
  }
}
