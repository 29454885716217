import { NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { NbCardModule } from '@nebular/theme';
import { TranslatePipe } from '@ngx-translate/core';
import { take } from 'rxjs';
import { SearchContainerComponent } from 'src/app/@shared/components/search/search-container/search-container.component';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  imports: [
    NbCardModule,
    NgIf,
    TranslatePipe,
    SearchContainerComponent
  ]
})
export class HomeComponent {
  userName: string;

  constructor(private authService: AuthService) {
    this.authService.user$.pipe(take(1)).subscribe((user) => {
      this.userName = user.given_name;
    });
  }
}
