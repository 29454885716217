import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { take } from 'rxjs';
import { resetSelectedAsset } from '../store/actions/asset.actions';
import { selectAllAssets, selectSelectedAsset } from '../store/selectors/assets.selectors';
import { selectCustomers, selectLines, selectNodeByLegacyId, selectNodeParent, selectPlants } from '../store/selectors/node.selectors';

@Injectable({
  providedIn: 'root',
})
export class FilterService {
  constructor(private store: Store) { }

  public loadItems(): Array<any> {
    const items = [];
    this.store.select(selectSelectedAsset).pipe(take(1)).subscribe((asset) => {
      if (asset) {
        if (asset?.containers?.length > 0) {
          asset?.containers.forEach((container) => {
            this.store.select(selectNodeByLegacyId, {
              legacyId: container.server_info.container_id,
              server: container.server_info.server
            }).pipe(take(1)).subscribe((node) => {
              items.push([node]);
            });
          });
          items.push([asset]);
        } else if (asset.parentPath === '/') {
          items.push([asset]);
          this.store.select(selectPlants).pipe(take(1)).subscribe((plants) => items.push(plants));
          this.store.select(selectLines).pipe(take(1)).subscribe((lines) => items.push(lines));
          this.store.select(selectAllAssets).pipe(take(1)).subscribe((assets) => items.push(assets));
        } else {
          const parentNames = asset.parentPath?.substring(1).split('/');
          parentNames?.forEach((parentName) => {
            this.store.select(selectNodeParent, {
              nodeParentPath: parentName
            }).pipe(take(1)).subscribe((node) => {
              items.push([node]);
            });
          });
          if (!parentNames) {
            this.store.select(selectCustomers).pipe(take(1)).subscribe((customers) => items.push(customers));
            this.store.select(selectPlants).pipe(take(1)).subscribe((plants) => items.push(plants));
            this.store.select(selectLines).pipe(take(1)).subscribe((lines) => items.push(lines));
          }
          items.push([asset]);
          switch(parentNames?.length) {
            case 1:
              this.store.select(selectLines).pipe(take(1)).subscribe((lines) => items.push(lines));
              this.store.select(selectAllAssets).pipe(take(1)).subscribe((assets) => items.push(assets));
              break;
            case 2:
              this.store.select(selectAllAssets).pipe(take(1)).subscribe((assets) => items.push(assets));
              break;
          }
        }
      } else {
        this.store.select(selectCustomers).pipe(take(1)).subscribe((customers) => items.push(customers));
        this.store.select(selectPlants).pipe(take(1)).subscribe((plants) => items.push(plants));
        this.store.select(selectLines).pipe(take(1)).subscribe((lines) => items.push(lines));
        this.store.select(selectAllAssets).pipe(take(1)).subscribe((assets) => items.push(assets));
      }
    })
    return items;
  }

  public clearFilter(item?: any): void {
    this.store.dispatch(resetSelectedAsset());
  }

}
