import { Injectable } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { MatomoTracker } from 'ngx-matomo-client';
import { CookieDetailsComponent } from 'src/app/@core/components/disclaimer/disclaimer-cookies/cookie-details/cookie-details.component';
import { CookieSettings } from 'src/app/@core/components/disclaimer/model/cookie-settings.model';
import { AppStateService } from 'src/app/@core/services/app-state.service';
@Injectable({
  providedIn: 'root',
})
export class CookieService {
  cookieSettings: CookieSettings;

  constructor(private dialogService: NbDialogService, private appState: AppStateService, private matomo: MatomoTracker) {
    const existingSettings = localStorage.getItem('cookieSettings');
    if (existingSettings) {
      this.cookieSettings = JSON.parse(existingSettings);
    } else {
      this.cookieSettings = {
        requiredAccepted: true,
        performanceAccepted: false,
      };
    }
  }

  openCookieDetails(): void {
    this.dialogService.open(CookieDetailsComponent, {
      autoFocus: false,
    }).onClose.subscribe((acceptedValues) => {
      if (acceptedValues) {
        this.acceptCookies(acceptedValues);
      }
    });
  }

  acceptCookies(values?: CookieSettings): void {
    // save default or callback
    if (values) {
      localStorage.setItem('cookieSettings', JSON.stringify(values));
    } else {
      this.cookieSettings.performanceAccepted = true;
      this.matomo.rememberConsentGiven();
      this.matomo.trackEvent('tracking', 'accept-all-cookies', 'accept-all-cookies');
      localStorage.setItem('cookieSettings', JSON.stringify(this.cookieSettings));
    }
    this.appState.showCookieConsent$.next(false);
  }
}
