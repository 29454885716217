import { NgClass, NgFor } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MachineType } from 'src/app/@core/model/machine-type.enum';
import { AppStateService } from 'src/app/@core/services/app-state.service';
import { AssetViewService } from 'src/app/@core/services/asset-view.service';
import { FilterItemComponent } from '../filter-item/filter-item.component';
import { FilterService } from '../filter.service';

@Component({
  selector: 'app-filter-container',
  templateUrl: './filter-container.component.html',
  styleUrls: ['./filter-container.component.scss'],
  imports: [
    NgFor,
    FilterItemComponent,
    NgClass
  ]
})
export class FilterContainerComponent implements OnInit, OnDestroy {
  items: any = [];
  isOpen = false;
  viewMode = 'fleet';
  subscription: Subscription;
  filterItems = [];

  constructor(
    private filterService: FilterService,
    private appState: AppStateService,
    private router: Router,
    private assetViewService: AssetViewService
  ) { }

  ngOnInit(): void {
    const it = [...this.filterService.loadItems()];
    this.items = it;
    this.appState.viewMode$.subscribe((mode) => {
      this.viewMode = mode;
    });
  }

  ngOnDestroy(): void {
    try {
      this.subscription.unsubscribe();
    } catch {
      // nop
    }
  }

  clear(item): void {
    const it = this.filterService.loadItems();
    const idx = this.items.findIndex((i) => i[0].id === item[0].id);
    it.splice(idx);
    if (it.length > 0 && it[0]?.length === 1) {
      this.gotoPageFor(it[it.length - 1][0]);
    } else {
      this.gotoPageFor(undefined);
    }
  }

  public gotoPageFor(item?: any): void {
    if (item === null || item === undefined) {
      this.router.navigate(['/dashboard/fleet/root/overview']);
      return;
    }
    switch (item.machineType) {
      case MachineType.customer:
        this.router.navigateByUrl(this.assetViewService.urlToCustomer(item, this.viewMode));
        break;
      case MachineType.plant:
        this.router.navigateByUrl(this.assetViewService.urlToPlant(item, this.viewMode));
        break;
      case MachineType.line:
        this.router.navigateByUrl(this.assetViewService.urlToLine(item));
        break;
      default:
        this.router.navigateByUrl(this.assetViewService.urlToDevice(item));
        break;
    }
  }

  checkOpen(event): void {
    this.isOpen = event;
  }
}
