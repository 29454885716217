<div class="mouseover">
  <div id="label-container" [ngSwitch]="timeSetting">
    <div *ngSwitchCase="'individual'">
      <div id="range">
        <div *ngIf="!onlyTimeZoneSelection" class="row m-0">
          <small class="rangeLabel">
            <label>{{ 'common.From' | translate }}:</label>
            <app-timestamp [timestamp]="timeRange.startTime"></app-timestamp>
          </small>
        </div>
        <div *ngIf="!onlyTimeZoneSelection" class="row m-0">
          <small class="rangeLabel">
            <label>{{ 'common.To' | translate }}:</label>
            <app-timestamp [timestamp]="timeRange.endTime"></app-timestamp>
          </small>
        </div>
        <small class="rangeLabel">
          <div *ngIf="onlyTimeZoneSelection">{{ timeZone }}</div>
        </small>
      </div>
    </div>

    <div *ngSwitchDefault>
      <div id="range">
        <div class="row m-0">
          <small class="rangeLabel">
            <div *ngIf="!onlyTimeZoneSelection">{{ 'dialogs.daterange.' + timeSetting | translate }} ({{ timeZone }})
            </div>
            <div *ngIf="onlyTimeZoneSelection">{{ timeZone }}</div>
          </small>
        </div>
      </div>
    </div>
  </div>

  <div id="calendar">
    <button nbButton ghost>
      <nb-icon icon="calendar-outline" class="black"> </nb-icon>
    </button>
  </div>
</div>
