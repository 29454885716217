import { NgFor } from '@angular/common';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NbAutocompleteModule, NbButtonModule, NbIconModule, NbInputModule } from '@nebular/theme';
import { TranslatePipe } from '@ngx-translate/core';
import { MatomoTracker } from 'ngx-matomo-client';
import { take } from 'rxjs';
import { INNodeModel } from 'src/app/@core/model/asset.model';
import { SearchService } from 'src/app/@shared/services/search.service';
import { SearchResult } from '../search-result/searchResult';

/**
 * wrapper component for (asset) search input
 * and grouped result list (e.g. customer, plant, line, devices...)
 */
@Component({
  standalone: true,
  selector: 'app-search-container',
  templateUrl: './search-container.component.html',
  styleUrls: ['./search-container.component.scss'],
  imports: [
    NbInputModule,
    NbAutocompleteModule,
    TranslatePipe,
    NbIconModule,
    NgFor,
    NbButtonModule
  ]
})
export class SearchContainerComponent {

  @ViewChild('autoInput') inputElement: ElementRef;

  searchTerm: string;
  searchResult: Array<SearchResult>;

  constructor(
    private matomoTracker: MatomoTracker,
    private router: Router,
    private searchService: SearchService
  ) { }

  search(): void {
    this.searchTerm = this.inputElement?.nativeElement?.value;
    this.runSearch();
  }

  runSearch(): any {
    this.searchService.search(this.searchTerm).pipe(take(1)).subscribe((result: Array<SearchResult>) => this.searchResult = result);
  }

  navigateTo(asset: INNodeModel): void {
    // search tracking
    let count = 0;
    this.searchResult?.forEach((result) => (count = count + result.children?.length));
    this.matomoTracker.trackSiteSearch(this.searchTerm, asset.machineType, count); // do not remove ;)

    // reset
    this.clearInput();

    // routing for selected element
    asset.machineType
      ? this.router.navigateByUrl(`dashboard/fleet/${asset.machineType.toLowerCase()}/${asset.id}`)
      : this.router.navigateByUrl(`dashboard/diagnose/type/${asset.typeId}/device/${asset.uuid}/overview`);
  }

  clearInput(): void {
    this.inputElement.nativeElement.value = '';
    this.searchTerm = '';
    this.searchResult = [];
  }
}
