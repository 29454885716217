import { NgClass, NgFor, NgIf } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NbMomentDateModule } from '@nebular/moment';
import {
  NbButtonGroupModule,
  NbButtonModule,
  NbCalendarModule,
  NbCalendarRangeModule,
  NbCardModule,
  NbDatepickerComponent,
  NbDatepickerModule,
  NbFormFieldModule,
  NbIconModule,
  NbInputModule,
  NbRangepickerComponent,
  NbTimePickerComponent,
  NbTimepickerModule,
} from '@nebular/theme';
import { Store, StoreModule } from '@ngrx/store';
import { TranslatePipe } from '@ngx-translate/core';
import { Moment } from 'moment';
import { MatomoTracker } from 'ngx-matomo-client';
import { Subscription } from 'rxjs';
import { skip, take } from 'rxjs/operators';
import { TimePeriod } from 'src/app/@core/model/time-period';
import { AppState } from 'src/app/store';
import { setIndividualTimerange, setPredefinedTimerange } from 'src/app/store/actions/time.actions';
import { TimeState } from 'src/app/store/reducers/time.reducer';
import { selectTime, selectTimeZone } from 'src/app/store/selectors/time.selector';
import { TimeSetting } from '../../model/timesetting.model';
import { TimezoneId } from '../../model/timezone.model';
import { LocaleService } from '../../services/locale.service';
import { TimeService } from '../../services/time.service';
import { DatePickerFormatAsyncValidator, invalidTimePeriodValidator } from '../../validators/date-picker.validator';
import { ErrorMessageContainerComponent } from '../error-message-container/error-message-container.component';
import { TimezoneSelectorComponent } from '../timezone-selector/timezone-selector.component';

@Component({
  standalone: true,
  selector: 'app-time-range-selector',
  templateUrl: './time-range-selector.component.html',
  styleUrls: ['./time-range-selector.component.scss'],
  imports: [
    NgClass,
    NgFor,
    NgIf,
    NbButtonModule,
    NbButtonGroupModule,
    NbCalendarModule,
    NbCalendarRangeModule,
    NbCardModule,
    NbDatepickerModule,
    NbFormFieldModule,
    NbIconModule,
    NbInputModule,
    NbMomentDateModule,
    NbTimepickerModule,
    ReactiveFormsModule,
    StoreModule,
    TranslatePipe,
    TimezoneSelectorComponent,
    ErrorMessageContainerComponent,
  ],
  providers: [TimeService, LocaleService],
})
export class TimeRangeSelectorComponent implements OnInit, AfterViewInit {
  @ViewChild('rangepicker', { static: false }) rangePicker: NbRangepickerComponent<Moment>;
  @ViewChild('timepickerFrom', { static: false }) from: NbTimePickerComponent<Moment>;
  @ViewChild('timepickerTo', { static: false }) to: NbTimePickerComponent<Moment>;
  @ViewChild('datepickerFrom', { static: false }) fromDate: NbDatepickerComponent<Moment>;
  @ViewChild('datepickerTo', { static: false }) toDate: NbDatepickerComponent<Moment>;
  @ViewChild('datepickerToInput', { static: false }) toDateInput: ElementRef;
  @ViewChild('datepickerFromInput', { static: false }) fromDateInput: ElementRef;

  @Input() onlyTimeZoneSelection = false;
  @Output() closeDialog: EventEmitter<boolean> = new EventEmitter();

  timePeriod: TimePeriod;
  timeSetting: TimeSetting;
  isTimeZoneUpdate = false;
  startTimeShiftLAbel: string;
  endTimeShiftLAbel: string;

  format: string;

  timeRangeForm: UntypedFormGroup = new UntypedFormGroup({
    fromTimeForm: new UntypedFormControl(),
    toTimeForm: new UntypedFormControl(),
    fromDateForm: new UntypedFormControl(),
    toDateForm: new UntypedFormControl(),
  });
  fromDateFormatValidator: DatePickerFormatAsyncValidator;
  toDateFormatValidator: DatePickerFormatAsyncValidator;
  showErrorMessage = false;

  timeRanges: Array<{ range: TimeSetting; isSelected: boolean }> = [
    // {
    //   range: 'today',
    //   isSelected: false,
    // },
    {
      range: '24h',
      isSelected: false,
    },
    // {
    //   range: 'week',
    //   isSelected: false,
    // },
    {
      range: 'month',
      isSelected: false,
    },
    {
      range: '3months',
      isSelected: false,
    },
    {
      range: '6months',
      isSelected: false,
    },
    {
      range: 'year',
      isSelected: false,
    },
  ];
  subscriptions: Subscription[] = [];

  constructor(
    private cdr: ChangeDetectorRef,
    protected timeService: TimeService,
    protected matomo: MatomoTracker,
    protected localeService: LocaleService,
    protected store: Store<AppState>,
  ) {
    this.format = this.timeService.getDateFormat();
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.localeService.language$.subscribe((lang) => {
        this.timeService.getDateFormat();

        if (this.fromDate) {
          (this.fromDate as any).format = this.format;
        }

        if (this.toDate) {
          (this.toDate as any).format = this.format;
        }

        this.cdr.detectChanges();
      }),
    );
  }

  ngAfterViewInit(): void {
    this.subscriptions.push(
      this.store
        .select(selectTime)
        .pipe(take(1))
        .subscribe((timestate: TimeState) => {
          this.timePeriod = new TimePeriod(timestate.range.startTime.clone(), timestate.range.endTime.clone());
          this.timeSetting = timestate.setting;

          this.timeRanges.forEach((r) => {
            r.isSelected = r.range === this.timeSetting;
          });

          this.timezoneConversion(timestate.zone);
          this.initializeForms();
        }),
    );

    this.subscriptions.push(
      this.store
        .select(selectTimeZone)
        .pipe(skip(1))
        .subscribe((timezone) => {
          this.timezoneConversion(timezone.zone, timezone.zoneInfo);
        }),
    );

    this.setRange();

    if (this.fromDate) {
      (this.fromDate as any).format = this.format;
    }
    if (this.toDate) {
      (this.toDate as any).format = this.format;
    }
  }

  initializeForms(): void {
    this.fromDateFormatValidator = new DatePickerFormatAsyncValidator(this.fromDateInput, this.format);
    this.toDateFormatValidator = new DatePickerFormatAsyncValidator(this.toDateInput, this.format);

    this.timeRangeForm = new UntypedFormGroup(
      {
        fromTimeForm: new UntypedFormControl(this.timePeriod.startTime),
        toTimeForm: new UntypedFormControl(this.timePeriod.endTime),
        fromDateForm: new UntypedFormControl(this.timePeriod.startTime, [Validators.required], this.fromDateFormatValidator.validate),
        toDateForm: new UntypedFormControl(this.timePeriod.endTime, [Validators.required], this.toDateFormatValidator.validate),
      },
      invalidTimePeriodValidator(this.timePeriod),
    );

    this.subscriptions.push(
      this.timeRangeForm.get('fromTimeForm').valueChanges.subscribe((val: Moment) => {
        this.matomo.trackEvent('date-time-range-selection', 'time', 'from');

        this.timePeriod.startTime.set('hours', val?.hour());
        this.timePeriod.startTime.set('minutes', val?.minute());
        this.timePeriod.startTime.set('seconds', val?.second());

        this.validateRange();
      }),
    );

    this.subscriptions.push(
      this.timeRangeForm.get('toTimeForm').valueChanges.subscribe((val: Moment) => {
        this.matomo.trackEvent('date-time-range-selection', 'time', 'to');

        this.timePeriod.endTime.set('hours', val?.hour());
        this.timePeriod.endTime.set('minutes', val?.minute());
        this.timePeriod.endTime.set('seconds', val?.second());

        this.validateRange();
      }),
    );

    this.subscriptions.push(this.timeRangeForm.get('fromDateForm').statusChanges.subscribe(() => this.validateRange()));
    this.subscriptions.push(
      this.timeRangeForm.get('fromDateForm').valueChanges.subscribe((val: Moment) => {
        if (!this.timeRangeForm.get('fromDateForm').invalid) {
          this.matomo.trackEvent('date-time-range-selection', 'date', 'from');

          this.timePeriod.startTime.set('year', val?.year());
          this.timePeriod.startTime.set('month', val?.month());
          this.timePeriod.startTime.set('date', val?.date());

          if (this.fromDate) {
            (this.fromDate as any).format = this.format;
          }

          this.setRange();
        } else if (this.timeRangeForm.get('fromDateForm').errors?.nbDatepickerParse) {
          this.timeRangeForm.get('fromDateForm').setErrors({ pattern: true });
        }

        this.validateRange();
      }),
    );

    this.subscriptions.push(this.timeRangeForm.get('toDateForm').statusChanges.subscribe(() => this.validateRange()));
    this.subscriptions.push(
      this.timeRangeForm.get('toDateForm').valueChanges.subscribe((val: Moment) => {
        if (!this.timeRangeForm.get('toDateForm').invalid) {
          this.matomo.trackEvent('date-time-range-selection', 'date', 'to');

          this.timePeriod.endTime.set('year', val?.year());
          this.timePeriod.endTime.set('month', val?.month());
          this.timePeriod.endTime.set('date', val?.date());

          if (this.toDate) {
            (this.toDate as any).format = this.format;
          }

          this.setRange();
        } else if (this.timeRangeForm.get('toDateForm').errors?.nbDatepickerParse) {
          this.timeRangeForm.get('toDateForm').setErrors({ pattern: true });
        }

        this.validateRange();
      }),
    );
  }

  formatDate(date: Moment): string {
    return date.format(this.format);
  }

  resetButtonSelection(): void {
    this.timeSetting = 'individual';
    this.timeRanges.forEach((r) => {
      r.isSelected = false;
    });
  }

  selectedRangeChange(event): void {
    this.resetButtonSelection();

    if (event.start) {
      this.matomo.trackEvent('date-time-range-selection', 'calendar', 'from');

      this.timePeriod.startTime.set('date', event.start.date());
      this.timePeriod.startTime.set('month', event.start.month());
      this.timePeriod.startTime.set('year', event.start.year());

      this.timeRangeForm.get('fromDateForm').setValue(this.timePeriod.startTime, { emitEvent: false });

      if (!event.end) {
        this.timePeriod.endTime.set('date', event.start.date());
        this.timePeriod.endTime.set('month', event.start.month());
        this.timePeriod.endTime.set('year', event.start.year());

        this.timeRangeForm.get('toDateForm').setValue(this.timePeriod.endTime, { emitEvent: false });

        this.cdr.detectChanges();
      }
    }
    if (event.end) {
      this.matomo.trackEvent('date-time-range-selection', 'calendar', 'to');

      this.timePeriod.endTime.set('date', event.end.date());
      this.timePeriod.endTime.set('month', event.end.month());
      this.timePeriod.endTime.set('year', event.end.year());

      this.timeRangeForm.get('toDateForm').setValue(this.timePeriod.endTime, { emitEvent: false });

      this.cdr.detectChanges();
    }

    this.validateRange();
  }

  focusLost(isFromPicker: boolean): void {
    setTimeout(() => {
      if (isFromPicker) {
        this.from._date = this.timePeriod.startTime;
        this.timeRangeForm.get('fromTimeForm').setValue(this.timePeriod.startTime);
      } else {
        this.to._date = this.timePeriod.endTime;
        this.timeRangeForm.get('toTimeForm').setValue(this.timePeriod.endTime);
      }
    }, 200);
  }

  keyDown(event, isFromPicker: boolean): void {
    if (event.key === 'Enter') {
      this.focusLost(isFromPicker);
    }
  }

  applyChanges(): void {
    if (!this.timeRangeForm.invalid) {
      this.subscriptions.forEach((sub) => sub.unsubscribe());
      this.store.dispatch(setIndividualTimerange({ range: this.timePeriod, trigger: 'timepicker' }));
      this.closeDialog.emit(true);
    } else {
      this.timeRangeForm.markAsTouched();
      this.timeRangeForm.updateValueAndValidity();
    }
  }

  setPredefinedTimerange(event: any): void {
    this.matomo.trackEvent('date-time-range-selection', 'predefined-ranges', event);
    this.subscriptions.forEach((sub) => sub.unsubscribe());
    this.store.dispatch(setPredefinedTimerange({ setting: event, trigger: 'timepicker' }));
    this.closeDialog.emit(true);
  }

  setRange(): void {
    if (this.rangePicker) {
      this.rangePicker.range = {
        start: this.timePeriod.startTime,
        end: this.timePeriod.endTime,
      };
      this.rangePicker.visibleDate = this.timePeriod.endTime;
    }

    this.cdr.detectChanges();
  }

  validateRange(): void {
    this.timeRangeForm.updateValueAndValidity();
    if (!this.isTimeZoneUpdate) {
      this.resetButtonSelection();
    }
  }

  getStatus(formName: string): string {
    return (this.timeRangeForm.errors?.invalidRange && this.timeRangeForm.touched && this.timeRangeForm.dirty) ||
      (this.timeRangeForm.get(formName).invalid && this.timeRangeForm.get(formName).touched && this.timeRangeForm.get(formName).dirty)
      ? 'danger'
      : 'basic';
  }

  timezoneConversion(tz?: TimezoneId, timezoneInfo?: string): void {
    this.timePeriod = this.timeService.convertTimePeriodToCurrentTimeZone(this.timePeriod, tz, timezoneInfo);
    // this.timePeriod.endTime = this.timeService.convertMomentToCurrentTimeZone(this.timePeriod.endTime, tz);
    this.startTimeShiftLAbel = this.timePeriod.startTime.format('Z');
    this.endTimeShiftLAbel = this.timePeriod.endTime.format('Z');

    this.isTimeZoneUpdate = true;
    this.timeRangeForm.get('fromTimeForm').setValue(this.timePeriod.startTime);
    this.timeRangeForm.get('toTimeForm').setValue(this.timePeriod.endTime);
    this.timeRangeForm.get('fromDateForm').setValue(this.timePeriod.startTime);
    this.timeRangeForm.get('toDateForm').setValue(this.timePeriod.endTime);
    setTimeout(() => {
      this.isTimeZoneUpdate = false;
    }, 100);
  }
}