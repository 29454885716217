import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { NbCardModule, NbIconModule } from '@nebular/theme';
import { TranslatePipe } from '@ngx-translate/core';
import { take } from 'rxjs';
import { Auth0Service } from '../../services/auth/auth.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
  imports: [
    NbCardModule,
    TranslatePipe,
    NbIconModule
  ]
})
export class LogoutComponent {
  constructor(private auth0: Auth0Service, private auth: AuthService, private router: Router) {
    this.auth.isAuthenticated$.pipe(take(1)).subscribe((isAuthenticated: boolean) => {
      if (isAuthenticated) {
        this.router.navigate(['/home']);
      }
    });
  }

  login(): void {
    this.auth0.login('/home');
  }
}
