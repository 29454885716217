import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { NbIconModule } from '@nebular/theme';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
  selector: 'app-filter-input',
  templateUrl: './filter-input.component.html',
  styleUrls: ['./filter-input.component.scss'],
  imports: [
    NbIconModule,
    TranslatePipe,
  ]
})
export class FilterInputComponent implements OnInit {
  @ViewChild('input', { static: true }) input: ElementRef;

  @Output() filter: EventEmitter<string> = new EventEmitter<string>();

  isInputShown = true;

  ngOnInit(): void {
    this.input.nativeElement.focus();
  }

  onInput(val: string): void {
    this.filter.emit(this.input.nativeElement.value);
  }
}
