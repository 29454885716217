import { NgModule } from '@angular/core';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { CopyrightComponent } from './components/copyright/copyright.component';
import { CookieDetailsComponent } from './components/disclaimer/disclaimer-cookies/cookie-details/cookie-details.component';
import { CookieComponent } from './components/disclaimer/disclaimer-cookies/cookie.component';
import { DisclaimerTermsComponent } from './components/disclaimer/disclaimer-terms/disclaimer-terms.component';
import { HeaderComponent } from './components/header/header.component';
import { LogoutComponent } from './components/logout/logout.component';
import { ProfileComponent } from './components/profile/profile.component';
import { VersionComponent } from './components/version/version.component';
import { AuthorizationProblemComponent } from './pages/authorization-problem/authorization-problem.component';
import { HomeComponent } from './pages/home/home.component';
import { Page404Component } from './pages/page404/page404.component';

const COMPONENTS = [
  CookieComponent,
  CookieDetailsComponent,
  DisclaimerTermsComponent,
  HeaderComponent,
  ProfileComponent,
  HomeComponent,
  Page404Component,
  CopyrightComponent,
  LogoutComponent,
  VersionComponent,
  AuthorizationProblemComponent,
  BreadcrumbComponent,
];

@NgModule({
  imports: [...COMPONENTS],
  exports: [...COMPONENTS],
})
export class CoreModule {}