import { NgFor, NgSwitch, NgSwitchCase } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { NbCardModule, NbCheckboxModule, NbSpinnerModule, NbToastrService } from '@nebular/theme';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { Subscription, take } from 'rxjs';
import { User } from 'src/app/@core/model/user.model';
import { AppStateService } from 'src/app/@core/services/app-state.service';
import { Auth0Service } from 'src/app/@core/services/auth/auth.service';
import { UserService } from 'src/app/@core/services/user.service';

@Component({
  selector: 'app-disclaimer-terms',
  templateUrl: './disclaimer-terms.component.html',
  styleUrls: ['./disclaimer-terms.component.scss'],
  imports: [
    NbSpinnerModule,
    NbCardModule,
    TranslatePipe,
    NgFor,
    NgSwitch,
    NgSwitchCase,
    NbCheckboxModule,
    FormsModule
  ]
})
export class DisclaimerTermsComponent implements OnInit, OnDestroy {
  settings: any;
  legalSettings: any;
  saveNotAllowed: boolean; // true unless all required docs have been accepted
  user: User;
  subscriptions: Array<Subscription> = [];
  loading = false;

  constructor(
    private appState: AppStateService,
    private auth0: Auth0Service,
    private auth: AuthService,
    private userService: UserService,
    private toastr: NbToastrService,
    private translate: TranslateService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.subscriptions.push(
      this.auth0.userSettings$.subscribe({
        next: (settings) => {
          if (settings) {
            this.settings = settings;
            const firstValueString = settings[Object.keys(settings)[0]]
              .map((setting) => setting.value); // we take the first and update all
            this.legalSettings = (<any>this.userService.cleanupSettingsString(firstValueString[0]))?.orgSettings.legalInfo.documents;
            this.saveNotAllowed = this.checkSaveNotAllowed();
          } else {
            this.router.navigateByUrl('home');
          }
        },
        error: () => {
          this.toastr.warning(this.translate.instant('users.msgLoadUserSettingsFailed'));
        }
      })
    );
  }

  ngOnDestroy(): void {
    try {
      this.subscriptions.forEach((s) => s.unsubscribe());
    } catch {
      // nop
    }
  }

  /**
   * accepted field initially holds a date -> we use it as a flag true/false
   * on accepting an item the acceptedAt field is being updated as well
   * @param legalSetting
   */
  updateSetting(legalSetting: any): void {
    if (legalSetting.accepted === false || legalSetting.accepted === undefined) {
      legalSetting.accepted = true;
      legalSetting.acceptedAt = new Date().toISOString();
    } else {
      legalSetting.accepted = false;
      legalSetting.acceptedAt = '';
    }
    this.saveNotAllowed = this.checkSaveNotAllowed();
  }

  saveSelected(): void {
    this.loading = true;
    if (this.saveNotAllowed) {
      this.toastr.warning(this.translate.instant('users.msgSaveUserSettingsFailed'));
      return;
    }
    this.updateAcceptedAt();
    this.auth.user$
      .pipe(take(1))
      .subscribe((user) => {
        const subSplit = user.sub.split('|');
        const username = subSplit[subSplit.length - 2] + '|' + subSplit[subSplit.length - 1];
        const usr: User = {
          email_verified: user.email_verified,
          family_name: user.family_name,
          given_name: user.given_name,
          name: user.name,
          nickname: user.nickname,
          picture: user.picture,
          sub: user.sub,
          updated_at: user.updated_at,
          username
        }
        Object.keys(this.settings).forEach((server) => {
          this.settings[server].forEach((setting) => {
            const settingValue = this.userService.cleanupSettingsString(setting.value);
            settingValue.orgSettings.legalInfo.documents = [...this.legalSettings];


            this.userService.saveSettingsToFacade(server, usr, settingValue).pipe(take(1)).subscribe({
              next: (response: any) => {
                if (response === null) {
                  this.appState.showTerms$.next(false);
                  this.auth0.userSettings$.next(this.settings);
                  this.router.navigateByUrl('home');
                }
              },
              error: () => {
                this.loading = false;
                this.toastr.warning(this.translate.instant('users.msgSaveUserSettingsFailed'));
                return;
              }
            });
          });
        })});
  }

  updateAcceptedAt(): void {
    this.legalSettings = this.legalSettings.map((doc) => {
      if (doc.confirm === true) {
        doc.accepted = true;
        doc.acceptedAt = new Date().toISOString();
      }
      return doc;
    });
  }

  acceptAll(): void {
    this.updateAcceptedAt();
    this.saveNotAllowed = this.checkSaveNotAllowed();
    this.saveSelected();
  }

  private checkSaveNotAllowed(): boolean {
    return this.legalSettings.some((setting) => setting.confirm === true && (setting.accepted === false || setting.accepted === undefined));
  }
}
