import { NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NbButtonModule, NbIconModule } from '@nebular/theme';
import { Store } from '@ngrx/store';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { Subscription, finalize, take } from 'rxjs';
import { INNodeModel } from 'src/app/@core/model/asset.model';
import { TimePeriod } from 'src/app/@core/model/time-period';
import { AssetService } from 'src/app/@core/services/asset.service';
import { NotificationService } from 'src/app/@core/services/notification.service';
import { AppState } from 'src/app/store';
import { loadingActionTypes } from 'src/app/store/actions/loading.actions';
import { TimeActionTypes, updateTimezone } from 'src/app/store/actions/time.actions';
import { TimeState } from 'src/app/store/reducers/time.reducer';
import { selectSelectedAsset } from 'src/app/store/selectors/assets.selectors';
import { selectTime, selectTimeState } from 'src/app/store/selectors/time.selector';
import { TimezoneId } from '../../model/timezone.model';
import { TimeService } from '../../services/time.service';
import { TimestampComponent } from '../timestamp/timestamp.component';

@Component({
  standalone: true,
  selector: 'app-time-range-selector-button',
  templateUrl: './time-range-selector-button.component.html',
  styleUrls: ['./time-range-selector-button.component.scss'],
  imports: [
    NgSwitch,
    NgSwitchCase,
    NgIf,
    TranslatePipe,
    TimestampComponent,
    NgSwitchDefault,
    NbIconModule,
    NbButtonModule
  ]
})
export class TimeRangeSelectorButtonComponent implements OnInit, OnDestroy {
  @Input() onlyTimeZoneSelection = false;

  timeRange: TimePeriod;
  timeSetting: string;
  timeZone: string;
  timeZoneInfo: string;

  selectedAsset;
  subscriptions: Subscription[] = [];

  constructor(
    protected store: Store<AppState>,
    private assetService: AssetService,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private timeService: TimeService
  ) { }

  ngOnInit(): void {
    this.subscriptions.push(
      this.store.select(selectTime).subscribe((timestate: TimeState) => {
        this.timeRange = timestate.range;
        this.timeSetting = timestate.setting;
        this.timeZone = timestate.zone;
      })
    );

    this.subscriptions.push(this.store.select(selectSelectedAsset).subscribe((asset: INNodeModel) => {
      if (asset && asset.uuid !== this.selectedAsset?.uuid) {
        if (asset?.containers?.length > 0) {
          this.store.dispatch(loadingActionTypes.load({
            categoryId: 'timezone',
            subcategoryId: asset.uuid,
            propertyPath: 'SDT.GeneralHeader.TimeZoneInfo'
          }));
          this.assetService.getTimezoneInfo('SDT.GeneralHeader.TimeZoneInfo', asset.uuid).pipe(take(1),
            finalize(() => this.store.dispatch(loadingActionTypes.removeLoadingElement(
              { categoryId: 'timezone', propertyPath: 'SDT.GeneralHeader.TimeZoneInfo' }))
            )
          ).subscribe({
            next: (data: any) => {
              const zones = moment.tz.names();
              const isValid = zones.find(zone => zone === data?.v) !== undefined;
              if (data?.v && isValid) {
                this.timeZoneInfo = data.v;
                this.store.dispatch(TimeActionTypes.updateAvailableZones({
                  zoneInfo: data.v,
                  availableZones: this.timeService.getTimeZones(true)
                }));
              } else {
                this.timeZoneInfo = undefined;
                this.store.dispatch(TimeActionTypes.updateAvailableZones({
                  zoneInfo: undefined,
                  availableZones: this.timeService.getTimeZones(false)
                }));
                this.store.select(selectTimeState).pipe(take(1)).subscribe((timestate: TimeState) => {
                  if (timestate.zone === TimezoneId.ASSET_LOCAL) {
                    if (asset !== undefined) {
                      this.notificationService.warning(
                        this.translateService.instant(
                          'control.timezone.messages.' + (data?.v ? 'incorrectConfigurationMsg' : 'missingConfigurationMsg')
                        ), this.translateService.instant(
                          'control.timezone.messages.' + (data?.v ? 'incorrectConfigurationTitle' : 'missingConfigurationTitle')
                        ), 0, true);
                    }

                    this.store.dispatch(TimeActionTypes.updateTimezoneFail());
                  }
                })
              }
            }
            ,
            error: (error: any) => {
              this.notificationService.warning(
                this.translateService.instant('control.timezone.messages.loadingFailedMsg'),
                this.translateService.instant('control.timezone.messages.loadingFailedTitle'), 0, true);

              this.store.dispatch(TimeActionTypes.updateTimezoneFail());
            }
          });
          this.selectedAsset = asset;
        } else {
          this.store.dispatch(TimeActionTypes.updateAvailableZones({
            zoneInfo: undefined,
            availableZones: this.timeService.getTimeZones(false)
          }));
          this.store.dispatch(updateTimezone({ zone: TimezoneId.UTC, zoneInfo: undefined }));
        }
      }
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
