<nb-card>
  <nb-card-body>
    <div class="message-wrapper">
      <h1>{{ 'service.msg.error.http401ShortHeader' | translate }}</h1>
      <p>{{ 'service.msg.error.http401ShortText' | translate }}</p>
      <h3>{{ 'service.msg.error.whatHappened' | translate }}</h3>
      <p>{{ 'service.msg.error.http401WhatHappenedAnswer' | translate }}</p>
      <h3>{{ 'service.msg.error.whatCanIDo' | translate }}</h3>
      <p>{{ 'service.msg.error.http401WhatCanIDoAnswer' | translate }}</p>
      <button nbButton hero class="button--hero"  (click)="loginWithRedirect()">Retry</button>
    </div>
  </nb-card-body>
</nb-card>
