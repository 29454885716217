import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbActionsModule, NbCardModule, NbDialogRef } from '@nebular/theme';
import { TranslatePipe } from '@ngx-translate/core';
import { QrCodeModule } from 'ng-qrcode';
import { Subscription } from 'rxjs';
import { LANG } from '../../services/language-keys';
import { LocaleService } from '../../services/locale.service';

const BADGES_PATH = 'assets/images/application-store-badges/';

@Component({
  selector: 'app-mobile-app',
  templateUrl: './mobile-app.component.html',
  styleUrl: './mobile-app.component.scss',
  imports: [
    NbCardModule,
    NbActionsModule,
    TranslatePipe,
    QrCodeModule
  ]
})
export class MobileAppComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();

  appleAppStoreBadge: string;
  appleAppStoreUrl: string;

  googlePlayBadge: string;
  googlePlayUrl: string;

  qrCodeUrl: string;

  constructor(protected dialogRef: NbDialogRef<any>, private localeService: LocaleService) {}

  ngOnInit(): void {
    this.appleAppStoreUrl = window.env.mobileAppAppleAppStoreUrl;
    this.googlePlayUrl = window.env.mobileAppGooglePlayUrl;
    this.qrCodeUrl = window.env.mobileAppQrCodeUrl;

    this.subscriptions.add(this.localeService.language$.subscribe((currentLanguage) => {
      switch(currentLanguage) {
        case LANG.DE:
          this.appleAppStoreBadge = BADGES_PATH + 'Download_on_the_App_Store_Badge_DE_RGB_blk_092917.svg';
          this.googlePlayBadge = BADGES_PATH + 'GetItOnGooglePlay_Badge_Web_color_German.png';
          break;
        case LANG.CN:
          this.appleAppStoreBadge = BADGES_PATH + 'Download_on_the_App_Store_Badge_CNSC_RGB_blk_092917.svg';
          this.googlePlayBadge = BADGES_PATH + 'GetItOnGooglePlay_Badge_Web_color_Chinese-CN.png';
          break;
        case LANG.ES:
          this.appleAppStoreBadge = BADGES_PATH + 'Download_on_the_App_Store_Badge_ES_RGB_blk_100217.svg';
          this.googlePlayBadge = BADGES_PATH + 'GetItOnGooglePlay_Badge_Web_color_Spanish.png';
          break;
        case LANG.PT:
          this.appleAppStoreBadge = BADGES_PATH + 'Download_on_the_App_Store_Badge_PTBR_RGB_blk_092917.svg';
          this.googlePlayBadge = BADGES_PATH + 'GetItOnGooglePlay_Badge_Web_color_Portuguese-BR.png';
          break;
        case LANG.RU:
          this.appleAppStoreBadge = BADGES_PATH + 'Download_on_the_App_Store_Badge_RU_RGB_blk_100317.svg';
          this.googlePlayBadge = BADGES_PATH + 'GetItOnGooglePlay_Badge_Web_color_Russian.png';
          break;
        case LANG.FR:
          this.appleAppStoreBadge = BADGES_PATH + 'Download_on_the_App_Store_Badge_FR_RGB_blk_100517.svg';
          this.googlePlayBadge = BADGES_PATH + 'GetItOnGooglePlay_Badge_Web_color_French.png';
          break;
        default:
          this.appleAppStoreBadge = BADGES_PATH + 'Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg'
          this.googlePlayBadge = BADGES_PATH + 'GetItOnGooglePlay_Badge_Web_color_English.png';
      }
    }));
  }

  close(): void {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
