import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  private selectedItem = 'Overview';

  getSelected(): string {
    return this.selectedItem;
  }

  setSelected(id): string {
    this.selectedItem = id;
    return this.selectedItem;
  }

  resetSelected(): string {
    return this.setSelected('Overview');
  }
}
