<div id="breadcrumbs">
  <span class="breadcrumbs-name" *ngFor="let crumb of crumbs; last as isLast">
    <a *ngIf="!isLast && crumb?.machineType?.toLowerCase() !== 'line'"
      [routerLink]="['/dashboard/fleet/' + crumb?.machineType?.toLowerCase() + '/' + crumb?.id]"
      nbTooltip="{{ crumb?.name }}" nbTooltipPlacement="bottom">{{ crumb?.name }}</a>
    <a *ngIf="!isLast && crumb?.machineType?.toLowerCase() === 'line'"
      [routerLink]="['/dashboard/diagnose/line/' + crumb?.id]" nbTooltip="{{ crumb?.name }}"
      nbTooltipPlacement="bottom">{{ crumb?.name }}</a>
    <a class="last" *ngIf="isLast" nbTooltip="{{ crumb?.name }}" nbTooltipPlacement="bottom">{{ crumb?.name }}</a>
    <span *ngIf="!isLast"> &nbsp; / &nbsp; </span>
  </span>
</div>
