import { NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { TranslatePipe } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { AppState } from 'src/app/store';
import { updateTimezone } from 'src/app/store/actions/time.actions';
import { selectCategoryIsLoading } from 'src/app/store/selectors/loading.selectors';
import { selectTimeZone } from 'src/app/store/selectors/time.selector';
import { TimezoneId } from '../../model/timezone.model';
import { TimeService } from '../../services/time.service';
import { InputExtendedComponent } from '../input-extended/input-extended.component';

@Component({
  selector: 'app-timezone-selector',
  templateUrl: './timezone-selector.component.html',
  styleUrls: ['./timezone-selector.component.scss'],
  imports: [
    InputExtendedComponent,
    TranslatePipe,
    NgIf
  ]
})
export class TimezoneSelectorComponent implements OnInit, OnDestroy {
  selectedTimezone: TimezoneId;
  selectedTimezoneInfo: string;
  showTimezoneInfo = false;
  timezoneList: Array<string>;
  subscriptions: Array<Subscription> = [];
  form: UntypedFormControl = new UntypedFormControl();

  isLoadingTimezone$: Observable<boolean>;

  readonly LOCAL_KEY = TimezoneId.ASSET_LOCAL;
  constructor(
    protected store: Store<AppState>,
    private timeService: TimeService
  ) {
    this.isLoadingTimezone$ = this.store.select(selectCategoryIsLoading('timezone'));
    this.timezoneList = this.timeService.getTimeZones(false);
  }

  ngOnInit(): void {
    this.subscriptions.push(this.store.select(selectTimeZone).subscribe(
      (timezone: { zone: TimezoneId, zoneInfo: string, availableZones: Array<TimezoneId> }) => {
        this.selectedTimezone = timezone.zone;
        this.form.setValue(this.selectedTimezone);
        this.selectedTimezoneInfo = timezone.zoneInfo;
        if (timezone.availableZones?.length > 0) {
          this.timezoneList = timezone.availableZones;
        }
        this.showTimezoneInfo = this.selectedTimezone === TimezoneId.ASSET_LOCAL && this.selectedTimezoneInfo !== undefined;
      }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  updateTimezone(tz: TimezoneId): void {
    this.store.dispatch(updateTimezone({ zone: tz, zoneInfo: this.selectedTimezoneInfo }));
  }
}
