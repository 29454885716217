<nb-card>
  <nb-card-header>{{ 'dialogs.language.title' | translate }}</nb-card-header>
  <nb-card-body>
    <nb-radio-group name="slangSelector" [(ngModel)]="selectedOption">
      <nb-radio *ngFor="let option of options" [checked]="option.checked" [value]="option.value" [name]="option.value">
        <img class="flag" [src]="option.url" />
        {{ 'dialogs.language.options.' + option.value | translate }}
      </nb-radio>
    </nb-radio-group>
    <nb-icon class="warning-icon" pack="eva" icon="alert-triangle-outline"></nb-icon>
    <p class="hint">{{ 'dialogs.language.hint.line1' | translate }} <br />{{ 'dialogs.language.hint.line2' | translate }}</p>
  </nb-card-body>
  <nb-card-footer>
    <button nbButton (click)="close()">
      {{ 'dialogs.close' | translate }}
    </button>
  </nb-card-footer>
</nb-card>
