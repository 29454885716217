import { Component } from '@angular/core';
import { SearchContainerComponent } from '../search/search-container/search-container.component';

@Component({
  selector: 'app-collapsed-search',
  templateUrl: './collapsed-search.component.html',
  styleUrls: ['./collapsed-search.component.scss'],
  imports: [
    SearchContainerComponent
  ]
})
export class CollapsedSearchComponent {}
