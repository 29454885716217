import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { skipWhile, take } from 'rxjs/operators';
import { MachineType } from 'src/app/@core/model/machine-type.enum';
import { selectAllNodes } from 'src/app/store/reducers/nodes.reducer';
import { selectAllAssets } from 'src/app/store/selectors/asset-store.selectors';
import { SearchResult } from '../components/search/search-result/searchResult';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  constructor(private store: Store) { }

  /**
   * Trigger search in hierarchy stored in store
   * @param term - the search string (part of an asset's hierarchy path, name, id or typeId)
   */
  public search(term: string, devicesOnly?: boolean): Observable<Array<SearchResult>> {
    return this.searchInStore(new RegExp(term, 'i'), devicesOnly);
  }

  private searchInStore(searchTerm: RegExp, devicesOnly?: boolean): Observable<Array<SearchResult>> {
    const result: Array<SearchResult> = [];
    this.store.select(selectAllNodes).pipe(skipWhile(nodes => nodes === undefined), take(1)).subscribe((nodes) => {
      const filteredNodes = [...nodes.filter((node) => {
        return (
          node.name?.search(searchTerm) > -1 ||
          (node.id + '')?.search(searchTerm) > -1 ||
          node.hierarchyPath?.search(searchTerm) > -1 ||
          node.machineType?.search(searchTerm) > -1
        )
      })];
      const customers = filteredNodes.filter(node => node.machineType === MachineType.customer);
      if (customers?.length > 0) {
        result.push({
          name: MachineType.customer,
          children: customers
        });
      }
      const plants = filteredNodes.filter(node => node.machineType === MachineType.plant);
      if (plants?.length > 0) {
        result.push({
          name: MachineType.plant,
          children: plants
        });
      }
      const lines = filteredNodes.filter(node => node.machineType === MachineType.line);
      if (lines?.length > 0) {
        result.push({
          name: MachineType.line,
          children: lines
        });
      }
    })
    this.store.select(selectAllAssets).pipe(skipWhile(assets => assets === undefined), take(1)).subscribe((assets) => {
      const filteredAssets = assets.filter((asset) => {
        return (
          asset.name?.search(searchTerm) > -1 ||
          asset.typeId?.search(searchTerm) > -1 ||
          (asset.id + '')?.search(searchTerm) > -1 ||
          asset.hierarchyPath?.search(searchTerm) > -1 ||
          asset.machineType?.search(searchTerm) > -1 ||
          asset.uuid?.search(searchTerm) > -1
        )
      })
      if (filteredAssets?.length > 0) {
        result.push({
          name: MachineType.device,
          children: [...filteredAssets]
        });
      }
    })
    return of(result);
  }
}
