import { NgFor, NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { NbTooltipModule } from '@nebular/theme';
import { Subscription, take } from 'rxjs';
import { INNodeModel } from '../../model/asset.model';
import { AssetStoreService } from '../../services/asset-store.service';
import { NodeService } from '../../services/node.service';
@Component({
  standalone: true,
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
  imports: [
    NgFor,
    NbTooltipModule,
    NgIf,
    RouterLink
  ]
})

export class BreadcrumbComponent implements OnInit, OnDestroy {
  crumbs: Array<INNodeModel> = [];
  subscription: Subscription;
  constructor(
    private assetStoreService: AssetStoreService,
    private nodeService: NodeService) {}

  ngOnInit(): void {
    this.subscription = this.assetStoreService.selectedAsset().subscribe((asset) => {
      if (asset) {
        const assetContainersWritable = Object.assign({}, asset.containers);
        this.crumbs = [];

        Object.keys(assetContainersWritable).sort()
          .forEach(key => {
            this.nodeService.selectNodeByLegacyId(
              assetContainersWritable[key].server_info.container_id,
              assetContainersWritable[key].server_info.server
            )
              .pipe(take(1))
              .subscribe((node) => {
                this.crumbs.push(node);
              })
          });
        if (Object.keys(assetContainersWritable)?.length === 0 && asset.parentPath !== '/') {
          const parentNames = asset.parentPath?.substring(1).split('/');
          parentNames?.forEach((parentName) => {
            this.nodeService.selectNodeParentByPath(parentName).pipe(take(1)).subscribe((node) => {
              this.crumbs.push(node);
            });
          });
        }
        this.crumbs.push(asset);
      } else {
        this.crumbs = [];
      }
    });
  }

  ngOnDestroy(): void {
    try {
      this.subscription.unsubscribe();
    } catch {
      // nop
    }
  }
}
