import { Component } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
  standalone: true,
  selector: 'app-copyright',
  templateUrl: './copyright.component.html',
  styleUrls: ['./copyright.component.scss'],
  imports: [TranslatePipe],
})
export class CopyrightComponent {
  currentYear = new Date().getUTCFullYear();
}
