<div class="filter-type" [ngClass]="this.open ? 'open' : 'closed'" (click)="toggle()">
  <p [ngClass]="valueSelected ? 'active' : 'inactive'">
    {{ 'leftPanel.filter.' + ( item[0]?.mType || item[0]?.machineType || item[0]?.refType || 'Device' )?.toLowerCase() | translate }}
  </p>
  <div class="filter-selection" *ngIf="valueSelected">
    <p>{{ valueSelected }}</p>
    <nb-icon icon="close-outline" (click)="clear($event)"></nb-icon>
  </div>
</div>
<div class="filter-item-names" *ngIf="open">
  <app-filter-input (filter)="filterWith($event)"></app-filter-input>
  <p class="filter-item-name" *ngFor="let node of (filteredNodes || allNodes)" (click)="select(node)">
    {{ node.name }}
  </p>
</div>
