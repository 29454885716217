<div *ngIf="user$ | async as user">
  <div class="row align-items-center profile-header">
    <div class="user-initials">
      <span>{{ user?.given_name?.substr(0, 1) }}</span>
      <span>{{ user?.family_name?.substr(0, 1) }}</span>
    </div>
  </div>
  <div class="row user-details">
    <div>
      <h2>{{ user?.given_name + ' ' + user?.family_name }}</h2>
      <p class="lead text-muted">{{ user?.email }}</p>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-12">
      <nb-card class="profile">
        <nb-card-body class="card-content">
          <nb-tabset #tabset fullwidth id="profileTabSet">
            <nb-tab tabId="user" tabTitle="{{ userInfo }}" lazyLoad="true">
              <div class="content row">
                <div class="col-md-6 col-lg-6" *ngIf="user">
                  <app-input [label]="'header.profilePage.lblUsername' | translate"
                    value="{{ user?.nickname | titlecase }}" [disabled]="true">
                  </app-input>
                  <app-input [label]="'header.profilePage.lblName' | translate" value="{{ user?.name }}"
                    [disabled]="true">
                  </app-input>
                  <app-input [label]="'header.profilePage.lblEmail' | translate" value="{{ user?.email }}"
                    [disabled]="true">
                  </app-input>
                  <app-input [label]="'header.profilePage.lblSingleSignOn' | translate" value="{{ user?.singleSignOn }}"
                    [disabled]="true"></app-input>
                </div>
                <div class="col-md-6 col-lg-6" *ngIf="user">
                  <app-input [label]="'header.profilePage.lblRole' | translate" value="{{ user?.role | titlecase }}"
                    [disabled]="true">
                  </app-input>
                  <app-input [label]="'header.profilePage.lblActive' | translate" value="{{ user?.active }}"
                    [disabled]="true">
                  </app-input>
                  <app-input [label]="'header.profilePage.lblId' | translate" value="{{ user?.id }}" [disabled]="true">
                  </app-input>
                  <app-input [label]="'header.profilePage.lblState' | translate" value="{{ user?.state | titlecase }}"
                    [disabled]="true">
                  </app-input>
                </div>
              </div>
            </nb-tab>
            <nb-tab tabId="settings" tabTitle="{{ settings }}">
              <div class="cookie-settings">
                <div class="content row">
                  <div class="col-md-12 col-lg-12">
                    <nb-accordion>
                      <nb-accordion-item>
                        <nb-accordion-item-header>{{ 'pages.disclaimer.settingLabel' | translate }}
                        </nb-accordion-item-header>
                        <nb-accordion-item-body>
                          <!-- <app-cookie-details></app-cookie-details> -->
                          <button nbButton (click)="openCookieDetails()">
                            {{ 'pages.disclaimer.open' | translate }}
                          </button>
                        </nb-accordion-item-body>
                      </nb-accordion-item>
                      <!-- <nb-accordion-item>
                        <nb-accordion-item-header>
                          Styles
                        </nb-accordion-item-header>
                        <nb-accordion-item-body>
                          <nb-toggle [checked]="darkmode" (checkedChange)="switchStyleMode($event)">
                            Dark Mode
                          </nb-toggle>
                        </nb-accordion-item-body>
                      </nb-accordion-item> -->
                    </nb-accordion>
                  </div>
                </div>
              </div>
            </nb-tab>
          </nb-tabset>
        </nb-card-body>
      </nb-card>
    </div>
  </div>
</div>
