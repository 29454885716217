import { NgClass, NgFor, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NbIconModule } from '@nebular/theme';
import { TranslatePipe } from '@ngx-translate/core';
import { INNodeModel } from 'src/app/@core/model/asset.model';
import { NodeService } from 'src/app/@core/services/node.service';
import { FilterInputComponent } from '../filter-input/filter-input.component';

@Component({
  selector: 'app-filter-item',
  templateUrl: './filter-item.component.html',
  styleUrls: ['./filter-item.component.scss'],
  imports: [
    NgClass,
    NbIconModule,
    FilterInputComponent,
    NgIf,
    TranslatePipe,
    NgFor
  ]
})
export class FilterItemComponent implements OnInit {
  @Input() item: any; // item containes all nodes for a type e.g. customer nodes, plant, device etc.
  @Output() selectEvent: EventEmitter<any> = new EventEmitter();
  @Output() clearEvent: EventEmitter<boolean> = new EventEmitter();
  @Output() toggleEvent: EventEmitter<boolean> = new EventEmitter();
  open = false;
  valueSelected: INNodeModel;
  allNodes: INNodeModel[];
  filteredNodes: INNodeModel[];

  constructor(private nodeService: NodeService) { }

  ngOnInit(): void {
    this.item = Object.assign([], this.item);
    if (this.item?.length === 1) {
      this.valueSelected = this.item[0]?.name;
    } else {
      this.allNodes = [...this.item];
    }
  }

  public toggle(): void {
    if (!this.valueSelected || (this.item && this.item.name)) {
      this.open = !this.open;
      this.toggleEvent.emit(this.open);
    }
  }

  public filterWith(term): void {
    this.filteredNodes = [
      ...this.allNodes.filter((node) => {
        return node?.name?.toLowerCase().indexOf(term?.toLowerCase()) > -1;
      }),
    ];
  }

  public select(item): void {
    this.selectEvent.emit(item);
    this.valueSelected = item.name;
    this.toggle();
    this.toggleEvent.emit(this.open);
  }

  public clear(event: MouseEvent): void {
    event.preventDefault();
    this.clearEvent.emit(true);
    this.valueSelected = undefined;
    this.open = true;
  }
}
