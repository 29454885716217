import { Injectable } from '@angular/core';
import { SessionStorageItem } from 'src/app/@core/model/session-storage-item.enum';
import { AssetService } from 'src/app/@core/services/asset.service';

@Injectable({
  providedIn: 'root',
})
export class SessionStorageService {
  constructor(private assetService: AssetService) {}
  public clear(): void {
    sessionStorage.clear();
  }

  public clearAssets(): void {
    this.removeItem(SessionStorageItem.hierarchyLegacy);
    this.removeItem(SessionStorageItem.customerLegacy);
    this.removeItem(SessionStorageItem.customerLegacyFiltered);
    this.removeItem(SessionStorageItem.plantLegacy);
    this.removeItem(SessionStorageItem.plantLegacyFiltered);
    this.removeItem(SessionStorageItem.lineLegacy);
    this.removeItem(SessionStorageItem.lineLegacyFiltered);
    this.removeItem(SessionStorageItem.assetsLegacy);
    this.removeItem(SessionStorageItem.assets);
    this.removeItem(SessionStorageItem.variablesOfSelectedAsset);
    this.assetService.deleteSelectedAssetFromStore();
  }

  public removeItem(itemKey: string): void {
    sessionStorage.removeItem(itemKey);
  }
}
