<img class="iq-suite" src="assets/images/IQ Suite.svg" />
<div class="row">
  <div class="col-sm-12 col-md-8 col-xxxl-8">
    <nb-card class="left">
      <nb-card-body>
        <div class="slogan">
          <p class="hello">{{ 'pages.home.start' | translate }}<br /></p>
        </div>
        <img class="home-image" src="assets/images/SIDRIVE IQ Formula Light.svg" />
        <button nbButton status="primary" class="button--login" (click)="login()">
          {{ 'pages.home.login' | translate }}
        </button>
      </nb-card-body>
    </nb-card>
  </div>
  <div class="col-sm-12 col-md-4 col-xxxl-4">
    <nb-card class="right">
      <nb-card-header>
      <p class="header">{{ 'pages.logout.header' | translate }}</p>
      </nb-card-header>
      <nb-card-body>
        <div>
          <p class="subheader">{{ 'pages.logout.highlights' | translate }}</p>
          <p>
            <nb-icon pack="eva" icon="plus-outline"></nb-icon>
            {{ 'pages.logout.highlight1' | translate }}
          </p>
          <p>
            <nb-icon pack="eva" icon="plus-outline"></nb-icon>
            {{ 'pages.logout.highlight2' | translate }}
          </p>
          <p>
            <nb-icon pack="eva" icon="plus-outline"></nb-icon>
            {{ 'pages.logout.highlight3' | translate }}
          </p>
          <p class="subheader">{{ 'pages.logout.features' | translate }}</p>
          <p class="subsubheader">
            {{ 'pages.logout.fleetOverview' | translate }}
          </p>
          <ul>
            <li>{{ 'pages.logout.fleetOverview1' | translate }}</li>
            <li>{{ 'pages.logout.fleetOverview2' | translate }}</li>
          </ul>
          <p class="subsubheader">
            {{ 'pages.logout.assetConfiguration' | translate }}
          </p>
          <ul>
            <li>{{ 'pages.logout.assetConfiguration1' | translate }}</li>
            <li>{{ 'pages.logout.assetConfiguration2' | translate }}</li>
            <li>{{ 'pages.logout.assetConfiguration3' | translate }}</li>
          </ul>
          <p class="subsubheader">{{ 'pages.logout.reports' | translate }}</p>
          <ul>
            <li>{{ 'pages.logout.reports1' | translate }}</li>
            <li>{{ 'pages.logout.reports2' | translate }}</li>
          </ul>
          <p class="subsubheader">{{ 'pages.logout.assetLogs' | translate }}</p>
          <ul>
            <li>{{ 'pages.logout.assetLogs1' | translate }}</li>
          </ul>
        </div>
      </nb-card-body>
    </nb-card>
  </div>
</div>

<div class="mobile">
  <nb-card class="mobile-card">
    <nb-card-body>
      <div class="slogan">
        <div id="gradient"></div>
        <p class="hello">{{ 'pages.home.start' | translate }}<br /></p>
      </div>
      <img class="background" src="assets/images/SIDRIVE IQ Formula Light.svg" />
      <button nbButton status="primary" class="button--login" (click)="login()">
        {{ 'pages.home.login' | translate }}
      </button>
    </nb-card-body>
  </nb-card>
</div>