import { Component, OnInit } from '@angular/core';
import { NbButtonModule } from '@nebular/theme';
import { TranslatePipe } from '@ngx-translate/core';
import { CookieService } from 'src/app/@shared/services/cookie.service.';
import { CookieSettings } from '../model/cookie-settings.model';

@Component({
  standalone: true,
  selector: 'app-cookie',
  templateUrl: './cookie.component.html',
  styleUrls: ['./cookie.component.scss'],
  imports: [
    TranslatePipe,
    NbButtonModule
  ]
})
export class CookieComponent implements OnInit {
  cookieSettings: CookieSettings;

  constructor(
    private cookieService: CookieService,
  ) { }

  ngOnInit(): void {
    this.cookieSettings = {
      requiredAccepted: true,
      performanceAccepted: false,
    };
  }

  openCookieDetails(): void {
    this.cookieService.openCookieDetails();
  }

  acceptCookies(values?: CookieSettings): void {
    this.cookieService.acceptCookies(values);
  }

  rejectCookies(): any {
    this.cookieService.acceptCookies(this.cookieSettings);
  }

}
