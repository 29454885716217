<nb-card class="mobile-app-card">
  <nb-card-header>
    <div>{{ 'dialogs.mobileApp.title' | translate }}</div>
    <nb-actions size="small">
      <nb-action class="close-button-action" (click)="close()" icon="close-outline"></nb-action>
    </nb-actions>
  </nb-card-header>
  <nb-card-body class="row">
    <div class="text-and-badges col-sm-12 col-lg-8">
      <div class="mobile-app-text">{{ 'dialogs.mobileApp.text' | translate }}</div>
      <div class="app-store-badges">
        <a [href]="appleAppStoreUrl" target="_blank">
          <img [src]="appleAppStoreBadge" />
        </a>
        <a [href]="googlePlayUrl" target="_blank">
          <img [src]="googlePlayBadge" />
        </a>
      </div>
    </div>
    <div id="qr-container" class="col-sm-12 col-lg-4">
      <qr-code [value]="qrCodeUrl" size="200" errorCorrectionLevel="L">
      </qr-code>
    </div>
  </nb-card-body>
  <nb-card-footer>
    <div class="mobile-app-legal-text">{{ 'dialogs.mobileApp.legalText' | translate }}</div>
  </nb-card-footer>
</nb-card>
