<nb-card>
  <nb-card-header>{{ 'dialogs.support.title' | translate }}</nb-card-header>
  <nb-card-body>
    <address>
      <nb-icon icon="email-outline"></nb-icon>
      <a class="value-column" [href]="'mailto: ' + ('dialogs.support.email.value' | translate)">
        {{ 'dialogs.support.email.value' | translate }}
      </a>
    </address>
  </nb-card-body>
  <nb-card-footer>
    <button nbButton (click)="close()">
      {{ 'dialogs.close' | translate }}
    </button>
  </nb-card-footer>
</nb-card>
