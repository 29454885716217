<div class="disclaimer-intro">
    <div class="disclaimerintro--text">
        <h4>{{ 'pages.disclaimer.header' | translate }}</h4>
        <p>{{ 'pages.disclaimer.intro' | translate }}</p>
    </div>
    <div class="disclaimer-intro--actions">
        <button nbButton (click)="rejectCookies()" outline>{{ 'pages.disclaimer.rejectLabel' |
            translate }}</button>
        <button nbButton outline (click)="openCookieDetails()">{{ 'pages.disclaimer.settingLabel' |
            translate }}</button>
        <button nbButton status="primary" (click)="acceptCookies()">{{ 'pages.disclaimer.acceptLabel' | translate
            }}</button>
    </div>
</div>
