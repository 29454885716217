<nb-card [nbSpinner]="loading">
  <nb-card-body class="card-content">
    <div>
      <nb-spinner *ngIf="!legalSettings"></nb-spinner>
      <h3>{{ 'pages.disclaimer.terms.header' | translate }}</h3>

      <div *ngFor="let legalSetting of legalSettings">
        <div [ngSwitch]="legalSetting.id">
          <div class="disclaimer-item" *ngSwitchCase="'privacyPolicy'">
            <h4>
              {{ 'pages.disclaimer.terms.privacyPolicy.linkLabel' | translate }}
            </h4>
            <nb-checkbox (checkedChange)="updateSetting(legalSetting)" [(ngModel)]="legalSetting.accepted">
              {{ 'pages.disclaimer.terms.privacyPolicy.textPre' | translate }}
              <a href="{{ legalSetting.url }}"
                target="_blank">{{ 'pages.disclaimer.terms.privacyPolicy.linkLabel' | translate }}</a>
              {{ 'pages.disclaimer.terms.privacyPolicy.textPost' | translate }}
            </nb-checkbox>
          </div>
          <div class="disclaimer-item" *ngSwitchCase="'termsOfUse'">
            <h4>{{ 'pages.disclaimer.terms.termsOfUse.linkLabel' | translate }}</h4>
            <nb-checkbox (checkedChange)="updateSetting(legalSetting)" [(ngModel)]="legalSetting.accepted">
              {{ 'pages.disclaimer.terms.termsOfUse.textPre' | translate }}
              <a href="{{ legalSetting.url }}"
                target="_blank">{{ 'pages.disclaimer.terms.termsOfUse.linkLabel' | translate }}</a>
            </nb-checkbox>
          </div>
          <div class="disclaimer-item" *ngSwitchCase="'openSource'">
            <h4>{{ 'pages.disclaimer.terms.openSource.linkLabel' | translate }}</h4>
            <nb-checkbox (checkedChange)="updateSetting(legalSetting)" [(ngModel)]="legalSetting.accepted">
              {{ 'pages.disclaimer.terms.openSource.textPre' | translate }}
              <a href="{{ legalSetting.url }}"
                target="_blank">{{ 'pages.disclaimer.terms.openSource.linkLabel' | translate }}</a>
            </nb-checkbox>
          </div>
        </div>
      </div>
      <p class="note">{{ 'pages.disclaimer.terms.note' | translate }}</p>
      <div class="buttons--save">
        <button nbButton status="basic" [disabled]="saveNotAllowed" (click)="saveSelected()">
          {{ 'pages.disclaimer.saveLabel' | translate }}
        </button>
        <button nbButton status="primary" (click)="acceptAll()">
          {{ 'pages.disclaimer.acceptLabel' | translate }}
        </button>
      </div>
    </div>
  </nb-card-body>
</nb-card>
