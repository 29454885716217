<input nbInput (input)="search()" type="text" [placeholder]="'pages.home.placeHolderSearch' | translate"
  [nbAutocomplete]="auto" #autoInput />

<nb-autocomplete #auto (selectedChange)="navigateTo($event)">
  <nb-option-group *ngFor=" let group of searchResult"
    [title]="'leftPanel.filter.' + group.name?.toLowerCase() | translate">
    <nb-option *ngFor="let option of group.children" [value]="option">
      {{ option.name }}
    </nb-option>
  </nb-option-group>
</nb-autocomplete>

<button nbSuffix nbButton (click)="clearInput()" ghost class="close-button">
  <nb-icon nbSuffix icon="close-outline" pack="nebular-essentials"></nb-icon>
</button>
