import { Component } from '@angular/core';
import { NbButtonModule, NbCardModule, NbDialogRef, NbIconModule } from '@nebular/theme';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
  selector: 'app-support',
  templateUrl: './technical-support.component.html',
  styleUrls: ['./technical-support.component.scss'],
  imports: [
    NbCardModule,
    TranslatePipe,
    NbIconModule,
    NbButtonModule
  ]
})
export class TechnicalSupportComponent {
  constructor(protected dialogRef: NbDialogRef<any>) {}

  close(): void {
    this.dialogRef.close();
  }
}
