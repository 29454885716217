import { Component } from '@angular/core';
import { NbCardModule } from '@nebular/theme';
import { TranslatePipe } from '@ngx-translate/core';
import { Auth0Service } from '../../services/auth/auth.service';

@Component({
  selector: 'app-authorization-problem',
  templateUrl: './authorization-problem.component.html',
  styleUrls: ['./authorization-problem.component.scss'],
  imports: [
    NbCardModule,
    TranslatePipe
  ]
})
export class AuthorizationProblemComponent {
  constructor(private auth0: Auth0Service) {}

  loginWithRedirect(): void {
    this.auth0.login();
  }
}
