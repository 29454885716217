<div class="disclaimer-details">
  <h4>{{ 'pages.disclaimer.detailHeader' | translate }}</h4>
  <p>{{ 'pages.disclaimer.detailText' | translate }}</p>
  <div class="form-group">
    <div class="row">
      <nb-checkbox status="basic" (checkedChange)="checkAll()" [(ngModel)]="acceptAllValue">{{
        'pages.disclaimer.allowLabel' | translate
      }}</nb-checkbox>
    </div>
    <div class="row">
      <nb-accordion>
        <nb-accordion-item>
          <nb-accordion-item-header>
            <nb-checkbox status="basic" disabled [ngModel]="cookieSettings.requiredAccepted">{{
              'pages.disclaimer.requiredCookies' | translate
            }}</nb-checkbox>
          </nb-accordion-item-header>
          <nb-accordion-item-body>
            {{ 'pages.disclaimer.requiredCookiesText' | translate }}
          </nb-accordion-item-body>
        </nb-accordion-item>
        <nb-accordion-item>
          <nb-accordion-item-header>
            <nb-checkbox status="basic" (checkedChange)="checkPerformance()"
              [(ngModel)]="cookieSettings.performanceAccepted">{{ 'pages.disclaimer.performanceCookies' | translate }}
            </nb-checkbox>
          </nb-accordion-item-header>
          <nb-accordion-item-body>
            {{ 'pages.disclaimer.performanceCookiesText' | translate }}
          </nb-accordion-item-body>
        </nb-accordion-item>
      </nb-accordion>
    </div>
  </div>
  <div class="selection-row">
    <button nbButton outline (click)="saveSelected()">{{ 'pages.disclaimer.saveLabel' | translate
      }}</button>
    <button nbButton status="primary" (click)="acceptAll()">{{ 'pages.disclaimer.acceptLabel' | translate }}</button>
  </div>
</div>
