import { NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';

@Component({
  standalone: true,
  selector: 'app-version',
  templateUrl: './version.component.html',
  styleUrls: ['./version.component.scss'],
  imports: [NgIf],
})
export class VersionComponent implements OnInit {
  version = window.env.version || '1.0.0';

  ngOnInit(): void {
    if (this.version !== 'undefinded') {
      const indexOfDash = this.version.indexOf('-');
      if (indexOfDash > -1) {
        this.version = this.version.substr(0, indexOfDash);
      }
    }
  }
}
