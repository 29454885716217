<nb-card>
  <nb-card-body>
    <div class="slogan">
      <p class="hello" data-matomo-mask>
        <b *ngIf="userName" data-matomo-mask>{{ 'pages.home.hello' | translate }} {{ userName + '!'}}</b>
        <br>
        <b>
          {{ 'pages.home.searchBeforeLineBreak' | translate }} <br />
          {{ 'pages.home.searchAfterLineBreak' | translate }}</b>
      </p>
      <app-search-container class="home-search"></app-search-container>
    </div>
    <div><img class="home-image" src="assets/images/SIDRIVE IQ Formula Light.svg"></div>
  </nb-card-body>
</nb-card>
