<div class="filters" *ngFor="let item of items">
  <div class="filter-item-wrapper" [ngClass]="isOpen ? 'open' : 'closed'">
    <app-filter-item
      [item]="item"
      (toggleEvent)="checkOpen($event)"
      (selectEvent)="gotoPageFor($event)"
      (clearEvent)="clear(item)"
    ></app-filter-item>
  </div>
</div>
